import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Home from "./pages/home";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import reportWebVitals from "./reportWebVitals";
import { GlobalContext, initialState } from "./state/global";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Search from "./pages/search";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <GlobalContext.Provider value={initialState}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="search" element={<Search />} />
          <Route path="store" element={<Home />} />
        </Routes>
      </GlobalContext.Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
