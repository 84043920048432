import { useState } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../../components/Logo";
import styles from "./home.module.css";

function Home() {
  const [searchQuery, setSearchQuery] = useState("");
  return (
    <div className={styles.wrapper}>
      <Logo position="Fixed" />
      <div className={styles.registerBox}>
        <div className={styles.registerBoxContainer}>
          <h3>Express your gratitude to</h3>
          <div className={styles.searchField}>
            <input
              type="text"
              value={searchQuery}
              placeholder="Name or Place"
              className={styles.searchBox}
              onChange={(e) => setSearchQuery(e.currentTarget.value)}
            />
            <Link
              to={`/search?q=${searchQuery}`}
              className={styles.registerButton}
            >
              Search <i className={styles.iconArrowRight} />
            </Link>
          </div>
          <p>
            You can find the person who helped you and express your gratitude by
            tipping them using Paypal or Bizum
          </p>
          <div className={styles.actionButtons}>
            <Link to="/register" className={styles.registerButton}>
              Register <i className={styles.iconArrowRight} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
