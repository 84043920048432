import { Employee } from "../../state/global";
import styles from "./popup.module.css";

interface PopupProps {
  employee: Employee;
  closePopup: () => void;
}

export const Popup = ({ employee, closePopup }: PopupProps) => {
  return (
    <div className={styles.popup}>
      <button className={styles.closePopupButton} onClick={closePopup}>
        x
      </button>
      <p className={styles.popupName}>{employee.name}</p>
      {employee.photo && (
        <img className={styles.popupPhoto} src={employee.photo} alt="" />
      )}
      {(employee.paypal || employee.bizum) && (
        <p className={styles.popupTipTitle}>Tip using</p>
      )}
      {employee.paypal && (
        <button
          className={styles.paypalButton}
          onClick={() => {
            alert("Still working on it!");
          }}
        />
      )}
      {employee.bizum && (
        <button
          className={styles.bizumButton}
          onClick={() => {
            alert("Still working on it!");
          }}
        />
      )}
    </div>
  );
};
