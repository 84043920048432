import { Link } from "react-router-dom";
import styles from "./logo.module.css";

interface LogoProps {
  position: "Fixed" | "Relative";
}

export const Logo = ({ position }: LogoProps) => {
  return (
    <Link to="/" className={styles[`logo${position}`]}>
      TipMeUp
    </Link>
  );
};
