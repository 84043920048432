import { createContext } from "react";

export interface NormalizedState<T> {
  [key: string]: T | string[];
  order: string[];
}

export interface Employee {
  id: string;
  storeId: string;
  name: string;
  photo?: string;
  paypal?: string;
  bizum?: string;
}

export interface Store {
  id: string;
  name: string;
}

interface GlobalState {
  employees: NormalizedState<Employee>;
  stores: NormalizedState<Store>;
}

export const initialState: GlobalState = {
  employees: {
    1: {
      id: "1",
      storeId: "store1",
      name: "Juan",
      photo: `${process.env.PUBLIC_URL}/temp-photos/cat.jpg`,
    },
    2: {
      id: "2",
      storeId: "store1",
      name: "Manuel",
      photo: `${process.env.PUBLIC_URL}/temp-photos/cat.jpg`,
      paypal: "userPaypal",
      bizum: "+123456789",
    },
    3: {
      id: "3",
      storeId: "store1",
      name: "Rosa",
      photo: `${process.env.PUBLIC_URL}/temp-photos/cat.jpg`,
      paypal: "userPaypal2",
    },
    4: {
      id: "4",
      storeId: "store1",
      name: "Mary",
      photo: `${process.env.PUBLIC_URL}/temp-photos/cat.jpg`,
      bizum: "+987654321",
    },
    order: ["1", "2", "3", "4"],
  },
  stores: {
    store1: {
      id: "store1",
      name: "Demo Store",
    },
    order: ["store1"],
  },
};

export const GlobalContext = createContext(initialState);
