import { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Logo } from "../../components/Logo";
import { Popup } from "../../components/Popup";
import {
  Employee,
  GlobalContext,
  NormalizedState,
  Store,
} from "../../state/global";
import styles from "./search.module.css";

const onTipButtonClick =
  (
    setPopup: (value: Employee | null) => void,
    employees: NormalizedState<Employee>,
    value: string
  ) =>
  () => {
    setPopup(employees[value] as Employee);
  };

function Search() {
  const [searchParams] = useSearchParams();
  const [popup, setPopup] = useState<Employee | null>(null);
  // TODO: Implement search
  const { employees, stores } = useContext(GlobalContext);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperContent}>
        <Logo position="Relative" />
        <h3 className={styles.labelSearch}>
          Results for {searchParams.get("q")}
        </h3>

        <ul className={styles.results}>
          {employees.order.map((employeeId) => {
            const { photo, name, storeId } = employees[employeeId] as Employee;
            return (
              <li className={styles.resultItem} key={employeeId}>
                {photo && <img src={photo} alt="" />}
                <p>
                  Name: <b>{name}</b>
                </p>
                <p>
                  Works at <b>{(stores[storeId] as Store).name}</b>
                </p>
                <button
                  className={styles.tipButton}
                  onClick={onTipButtonClick(setPopup, employees, employeeId)}
                >
                  Tip
                </button>
              </li>
            );
          })}
        </ul>
        {popup && (
          <Popup
            closePopup={() => {
              setPopup(null);
            }}
            employee={popup}
          />
        )}
      </div>
    </div>
  );
}

export default Search;
